<template>
    <span class="external-network-container">
        <b-field label="RBP administrator">
            <CustomSelect :options="getRBPNetworkOptions()" placeholder="Select"
                @input="handleSelect($event, 'rbpAdministrator')" :default="value.rbpAdministrator" :type="showDeepValidationErrors &&
                        externalMissingValue &&
                        !value.rbpAdministrator
                        ? 'is-danger'
                        : ''
                    ">
            </CustomSelect>
        </b-field>
        <b-field class="multiselect-field" label="Third party network">
            <multiselect
            :options="getThirdPartyNetworkOptions()"
            placeholder="Select"
            :limit="4"
            @onSelect="handleSelect($event, 'thirdPartyNetwork')"
            :value="value.thirdPartyNetwork ? value.thirdPartyNetwork?.split(',') : null"
            :error="showDeepValidationErrors &&
                        externalMissingValue &&
                        !value.thirdPartyNetwork
                    ">
                >
            </multiselect>
        </b-field>

        <div class="vpc--radio-container" v-if="externalNeedsVpcValue">
            <label class="label">Does this site require VPC module?</label>
            <div class="radioDiv">
                <b-field class="radioField" v-bind:class="showDeepValidationErrors && (value.requireVpcModule === null || value.requireVpcModule === undefined)
                        ? 'radioField radio-error'
                        : 'radioField'
                    ">
                    <b-radio id="vpcModule" type="radio" size="is-medium" v-bind:native-value="false"
                        v-model="value.requireVpcModule" @input="setIsValid">
                        No
                    </b-radio>
                </b-field>
                <b-field class="radioField" v-bind:class="showDeepValidationErrors && (value.requireVpcModule === null || value.requireVpcModule === undefined)
                        ? 'radioField radio-error'
                        : 'radioField'
                    ">
                    <b-radio id="vpcModule" type="radio" size="is-medium" v-bind:native-value="true"
                        v-model="value.requireVpcModule" @input="setIsValid">
                        Yes
                    </b-radio>
                </b-field>
            </div>
        </div>
        <span v-if="showDeepValidationErrors &&
            (externalMissingValue || externalNeedsVpcValue)
            " class="duplicateNameError externalMissingError">
            <span><img src="@/assets/alert-circle@1x.svg" alt="" /></span>
            You must select an option to proceed
        </span>
        <!-- <json-viewer theme="my-awesome-json-theme" :value="value" :expand-depth="2" /> -->
    </span>
</template>

<script>
import providerApi from '@/api/providerApi'
import CustomSelect from '@/components/custom-select.vue'
import Multiselect from '@/components/multi-select.vue'
// import JsonViewer from 'vue-json-viewer'

export default {
  name: 'external-network-fields',
  props: {
    value: Object,
    showDeepValidationErrors: Boolean,
    externalMissingValue: Boolean,
    externalNeedsVpcValue: Boolean
  },
  components: {
    CustomSelect,
    Multiselect
    // JsonViewer
  },
  data () {
    return {
      rbpAdministrator: providerApi.getRBRNetworks(),
      thirdPartyNetwork: providerApi.getThirdPartyNetworks()
    }
  },
  methods: {
    getRBPNetworkOptions () {
      return [...this.rbpAdministrator, 'None']
    },
    getThirdPartyNetworkOptions () {
      return [...this.thirdPartyNetwork, 'None']
    },
    handleSelect (value, network) {
      this.value[network] = value.toString()
      this.setIsValid()
    },
    setIsValid () {
      this.$emit('setIsValid')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.external-network-container {
    .custom-select {
        width: 515px !important;
    }

    .multiselect-field {
        width: 515px;

        .multiselect__tags {
            height: 51px;
            padding-left: 17.6px;
        }
    }

    .duplicateNameError {
        margin-top: 10px;
    }
}

.vpc--radio-container {
    display: block;
    text-align: left;
    max-height: 65px;

    .radioDiv {
        display: flex;
        margin-top: 15px;

        .radioField {
            margin-right: 35px;
        }

        input[type="radio"]:checked+.check {
            border: 1px solid #5586ef;
        }

        input[type="radio"]:checked+.check:before {
            transform: scale(0.7);
            background-color: #5586ef;
        }

        .b-radio.radio:hover input[type="radio"]:not(:disabled)+.check {
            border-color: #5586ef;
        }
    }

    .b-radio {
        .control-label {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #444444;
        }
    }

    .radio-error {
        .b-radio.radio input[type="radio"]+.check {
            border: 1px solid #d62020 !important;
        }
    }
}
</style>
