var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"external-network-container"},[_c('b-field',{attrs:{"label":"RBP administrator"}},[_c('CustomSelect',{attrs:{"options":_vm.getRBPNetworkOptions(),"placeholder":"Select","default":_vm.value.rbpAdministrator,"type":_vm.showDeepValidationErrors &&
                    _vm.externalMissingValue &&
                    !_vm.value.rbpAdministrator
                    ? 'is-danger'
                    : ''},on:{"input":function($event){return _vm.handleSelect($event, 'rbpAdministrator')}}})],1),_c('b-field',{staticClass:"multiselect-field",attrs:{"label":"Third party network"}},[_c('multiselect',{attrs:{"options":_vm.getThirdPartyNetworkOptions(),"placeholder":"Select","limit":4,"value":_vm.value.thirdPartyNetwork ? _vm.value.thirdPartyNetwork?.split(',') : null,"error":_vm.showDeepValidationErrors &&
                    _vm.externalMissingValue &&
                    !_vm.value.thirdPartyNetwork},on:{"onSelect":function($event){return _vm.handleSelect($event, 'thirdPartyNetwork')}}},[_vm._v(" > ")])],1),(_vm.externalNeedsVpcValue)?_c('div',{staticClass:"vpc--radio-container"},[_c('label',{staticClass:"label"},[_vm._v("Does this site require VPC module?")]),_c('div',{staticClass:"radioDiv"},[_c('b-field',{staticClass:"radioField",class:_vm.showDeepValidationErrors && (_vm.value.requireVpcModule === null || _vm.value.requireVpcModule === undefined)
                    ? 'radioField radio-error'
                    : 'radioField'},[_c('b-radio',{attrs:{"id":"vpcModule","type":"radio","size":"is-medium","native-value":false},on:{"input":_vm.setIsValid},model:{value:(_vm.value.requireVpcModule),callback:function ($$v) {_vm.$set(_vm.value, "requireVpcModule", $$v)},expression:"value.requireVpcModule"}},[_vm._v(" No ")])],1),_c('b-field',{staticClass:"radioField",class:_vm.showDeepValidationErrors && (_vm.value.requireVpcModule === null || _vm.value.requireVpcModule === undefined)
                    ? 'radioField radio-error'
                    : 'radioField'},[_c('b-radio',{attrs:{"id":"vpcModule","type":"radio","size":"is-medium","native-value":true},on:{"input":_vm.setIsValid},model:{value:(_vm.value.requireVpcModule),callback:function ($$v) {_vm.$set(_vm.value, "requireVpcModule", $$v)},expression:"value.requireVpcModule"}},[_vm._v(" Yes ")])],1)],1)]):_vm._e(),(_vm.showDeepValidationErrors &&
        (_vm.externalMissingValue || _vm.externalNeedsVpcValue)
        )?_c('span',{staticClass:"duplicateNameError externalMissingError"},[_vm._m(0),_vm._v(" You must select an option to proceed ")]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/alert-circle@1x.svg"),"alt":""}})])
}]

export { render, staticRenderFns }