
import { defineComponent } from 'vue'
import { PropType } from 'vue/types/v3-component-props'

import Alert from '@/components/alert.vue'
import CheckIcon from '@/components/check-icon.vue'
import AlertModal from '@/components/alert-modal.vue'
import client from '@/api/networkConfigApi'
import { cloneDeep, isEqual } from 'lodash'
import { isSuccessResponse } from '@/helpers/is-success-response'
import { AxiosResponse } from 'axios'

const domainName = process.env.VUE_APP_API_URL?.split('.').pop() || 'com'

const charactersAllowedInUrlRegex = /^[A-Za-z0-9.-]*$/

export interface PreEnrollmentData {
  customContent: boolean;
  siteURL: string;
  publish?: boolean
  preEnrollment: Record<string, unknown>
}

export default defineComponent({
  name: 'pre-enrollment-form',
  components: {
    CheckIcon,
    AlertModal,
    Alert
  },
  props: {
    value: {
      required: false,
      type: Object as PropType<PreEnrollmentData>,
      default () {
        return {
          customContent: undefined,
          siteURL: ''
        }
      }
    },
    clientData: {
      required: false,
      type: Object as PropType<PreEnrollmentData>
    },
    handleSubmit: {
      required: true,
      type: Function as PropType<(data: PreEnrollmentData) => AxiosResponse<any>>
    }
  },
  data () {
    return {
      data: cloneDeep(this.value),
      shouldShowUrlCheck: false,
      isCheckingURL: false,
      urlError: null as string | null,
      isUrlUnique: null,
      isSaveModalOpen: false,
      isOEURLDisabled: false,
      showUnpublishWarning: false,
      isConfirmModalOpen: false,
      savedSuccess: false,
      isPublishModalOpen: false,
      isUnpublishModalOpen: false,
      isPublishModalSuccess: false,
      isUnpublishModalSuccess: false,
      isConfirmRemoveCustomModalOpen: false,
      isUrlValid: false,
      canDeleteCustomContent: false,
      domainName
    }
  },
  computed: {
    isPublishDisabled: function (): boolean {
      return !this.data.siteURL || this.data.customContent !== false || this.isUrlUnique === false || this.isUrlValid === false || !!this.urlError
    }
  },
  watch: {
    value: function (newVal) {
      if (!isEqual(newVal, this.data)) {
        this.isOEURLDisabled = !!this.value.siteURL
        this.isUrlValid = !!this.value.siteURL

        this.data = cloneDeep(newVal)
        this.showUnpublishWarning = newVal.publish === true
      }
    },
    clientData: {
      handler: function () {
        this.shouldShowUrlCheck = false
        this.isUrlUnique = null
        this.isOEURLDisabled = !!this.data.siteURL
      },
      deep: true
    },
    data: {
      handler: function (newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    removeCustomContent () {
      if (!this.canDeleteCustomContent && this.clientData?.preEnrollment.customContent === true) {
        this.$nextTick(() => {
          this.data.customContent = true
        })

        this.isConfirmRemoveCustomModalOpen = true
      }
    },
    onConfirmCustomContentRemove () {
      this.data.customContent = false
      this.isConfirmRemoveCustomModalOpen = false
      this.canDeleteCustomContent = true
    },

    onClickSubmit () {
      if (this.clientData?.preEnrollment.customContent) {
        // don't publish
      } else {
        this.isPublishModalSuccess = false
        this.isUnpublishModalSuccess = false

        if (this.value.publish) {
          this.isUnpublishModalOpen = true

          return
        }
        this.isPublishModalOpen = true
      }
    },

    async onSubmit () {
      this.isPublishModalSuccess = false
      this.isUnpublishModalSuccess = false

      const response = await this.handleSubmit(this.data)

      if (isSuccessResponse(response)) {
        this.isPublishModalSuccess = true
        this.isUnpublishModalSuccess = true

        if (this.isSaveModalOpen) {
          this.isPublishModalOpen = true
          this.isSaveModalOpen = false
        }
      } else {
        this.isPublishModalOpen = false
        this.isUnpublishModalOpen = false
      }
    },

    async checkURL (e: Event) {
      if (e.type === 'blur' && this.isUrlUnique !== null) {
        return
      }

      this.urlError = null
      this.isUrlUnique = null
      this.shouldShowUrlCheck = false
      const url = (e.target as HTMLInputElement).value as string

      if (e.type === 'keydown' && (e as KeyboardEvent).key !== 'Enter') {
        return
      }

      if (!charactersAllowedInUrlRegex.test(url) || !(url.endsWith('.centivo.com') || url.endsWith('.centivo.dev') || url.endsWith('.centivo.live'))) {
        this.urlError = 'URL is invalid'
        this.$emit('setPreEnrollmentInvalid', true)
        return
      }

      this.isCheckingURL = true
      this.shouldShowUrlCheck = true
      try {
        const { unique, valid } = await client.checkOeURL(url)

        this.isUrlUnique = unique
        this.isUrlValid = valid
        this.$emit('setPreEnrollmentInvalid', !unique || !valid)

        if (!valid) {
          this.urlError = 'URL is invalid'
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.isCheckingURL = false
      }
    },

    editURL () {
      this.isConfirmModalOpen = true
    },

    onConfirmEditURL () {
      this.isOEURLDisabled = false
      this.isConfirmModalOpen = false
    }
  }
})
