<template>
    <span class="multiselect-wrapper-span">
        <multiselect
            :options="sortedOptions"
            :multiple="true"
            v-bind:class="error ? 'multiselect-field is-danger' : 'multiselect-field'"
            :show-labels="false"
            :close-on-select="false"
            :limit="limit"
            :limit-text="getLimitText"
            v-model="selections"
            :placeholder="placeholder"
            @input="sortOptions"
        >
            <span class="chevron" slot="caret" slot-scope="{ toggle }" @mousedown.prevent.down="toggle">
                <i class="fa fa-chevron-down"></i>
            </span>
            <template v-slot:clear v-if="!!selections && selections.length > 0">
                <span class="clear" >
                    <span class="clearButton">
                        <img @click="clearSelection()" src="@/assets/x-circle@1x.svg" alt=""/>
                    </span>
                    <span class="divider"></span>
                </span>
            </template>
            <template slot="option" slot-scope="scope">
                <span v-bind:class="lastSelectedOption === scope.option ? 'custom-option-container last-selected-option' : 'custom-option-container'">
                    <span class="custom-checkbox"></span>
                    <span class="custom-option">{{ scope.option }}</span>
                </span>
            </template>
        </multiselect>
    </span>
</template>

<script>
import Multiselect from './multi-select-wrapper.vue'

export default {
  extends: Multiselect,
  name: 'vue-multiselect',
  props: {
    placeholder: String,
    onSelectData: {
      required: false
    },
    error: Boolean
  },
  components: {
    Multiselect
  },
  data () {
    return {
      lastSelectedOption: null,
      selections: this.value,
      sortedOptions: this.options
    }
  },
  methods: {
    getLimitText (count) {
      return '+' + count
    },
    sortOptions () {
      const oldSelection = [...this.selections]
      this.sortedOptions = this.selections.sort()
      if (this.sortedOptions.length > 0 && this.sortedOptions.length < this.options.length) {
        this.lastSelectedOption = this.sortedOptions[this.sortedOptions.length - 1]
      } else {
        this.lastSelectedOption = null
      }

      const unselected = this.options.sort()
      for (const option of unselected) {
        if (this.sortedOptions.indexOf(option) > -1) {
          continue
        }
        this.sortedOptions.push(option)
      }

      this.selections = oldSelection
      this.$emit('onSelect', this.selections, this.onSelectData)
    },
    clearSelection () {
      this.selections = []
      this.sortOptions()
      this.$emit('onSelect', this.selections, this.onSelectData)
    }
  },
  watch: {
    value: function (newVal) {
      this.selections = newVal
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
  .multiselect-wrapper-span {
    position: relative;
        height: 48.97px !important;

    input::placeholder{
        color: lightgray !important;
    }

    .multiselect__tags {
        height: 49px;
        background-color: white;
        border-radius: 4px;
        border: 2px solid lightgrey;
        padding-left: 1em;
        padding-right: 1em;
    }

    .multiselect__tag {
        margin-bottom: 0;
        margin-top: 5px;
        background: #ebebeb;
        color: #444444;
        font-weight: 600;
        font-size: 10px;
        padding-left: 6px;
        padding-right: 24px;
        padding-top: 6px;
        padding-bottom: 6px;
        border-radius: 2px;
    }

    .multiselect__tag-icon {
        right: -5px;
        background: url("../assets/x-circle@half.svg") !important;
        background-repeat: no-repeat !important;
        margin-top: 6px;
    }

    .multiselect__tag-icon::after {
        display: none;
    }

    .multiselect__tags .multiselect__placeholder {
        font-size: 1.1rem;
        color: lightgrey;
        font-weight: 400;
        font-family: 'Open Sans', sans-serif;
    }
    .multiselect__tags .multiselect__input {
        font-size: 17.5px;
        padding: 0px;
        padding-top: 5px;
        padding-bottom: 5px;
        height: inherit;
    }

    .multiselect .multiselect__content-wrapper {
        box-shadow: 0px 0px 12px -8px grey;
        color: black;
        border-radius: 6px 6px 6px 6px;
        overflow-x: hidden;
        overflow-y: overlay;
        border: 2px solid lightgrey;
    }

    .multiselect-field {
        width: 247.5px;
    }

    span.chevron {
        position: absolute;
        top: 0;
        right: 19px !important;
        height: 51px;
        display: flex;
        align-items: center;
        color: #7a7a7a !important;
        font-size: 18px;
        z-index: 1;
        cursor: pointer;
    }

    .multiselect--active {
        span.chevron {
            transform: rotate(180deg);
        }

        .multiselect__tags {
            border: 1px solid rgb(38, 132, 255);
            border-radius: 4px;
        }
    }

    span.clear {
        position: absolute;
        top: 0;
        right: 40px !important;
        height: 51px;
        pointer-events: none;
        display: flex;
        align-items: center;
        color: #7a7a7a !important;
        font-size: 18px;
        z-index: 1;
    }

    span.clearButton {
        margin-top: 7px;
        cursor: pointer;
        pointer-events: all;
    }
    span.divider {
        width: 1px;
        height: 24px;
        border-right: 1px solid #bbbdbf;
        margin-top: 24px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .multiselect__strong {
        margin-top: 5px;
        float: right;
        margin-right: 65px;
    }

    .custom-checkbox  {
        width: 1.5rem;
        min-width: 1.5rem;
        height: 1.5rem;
        min-height: 1.5rem;
        margin-right: 0.75rem;
        border: 1px solid #bbbdbf;
        display: inline-block;
        border-radius: 0.25rem;
        vertical-align: -0.3125rem;
        position: relative;
        top: 1px;
    }

    .multiselect__option {
        padding: 0;
    }

    .multiselect__option:before {
        width: 25px;
        height: 25px;
        margin: 0 8px 0 0;
        border-radius: 4px;
        border: solid 1px #bbbdbf;
        background-color: white;
        color: white;
        content: 'hh';
        cursor: pointer;
        display: none;
    }

    .multiselect__option--highlight {
        background: white;
        color: inherit;
    }

    .multiselect__option--selected .custom-checkbox{
        background-color: #5586ef;
        border-color: #5586ef;
    }

    .multiselect__option--selected .custom-checkbox::before {
        content: "";
        width: 1rem;
        height: 0.5625rem;
        position: absolute;
        display: inline-block;
        border-bottom: 0.125rem solid #fff;
        border-left: 0.125rem solid #fff;
        transform: rotate(-50deg);
        left: 0.1875rem;
        top: 0.25rem;
    }

    .multiselect__option--selected.multiselect__option--highlight {
        background: #fff;
        :after {
            display: none;
        }
    }

    .multiselect__option--selected {
        background: #fff;
        color: rgb(33, 37, 41);
        font-weight: 500;
    }

    .custom-option-container {
        width: 100%;
        padding: 12px;
        display: block;
    }

    .custom-option {
        font-weight: 500;
        font-size: 16px;
        color: rgb(33, 37, 41);
    }

    .last-selected-option {
        border-bottom: 1px solid rgb(235, 235, 235);
    }

    .is-danger {
        .multiselect__tags {
            border: 1px solid #d62020 !important;
        }
    }
}
</style>
