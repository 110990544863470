<template>
<div class="network-series--container">
  <slot />
</div>
</template>

<script>
export default {
  name: 'network-series-container'
}
</script>

<style lang="scss">
.network-series--container {
  border: 1px solid #BBBDBF;
  border-radius: 4px;
  padding: 1.25rem 1rem;
  margin-top: 1.75rem;
  width: 35rem;
  margin-left: auto;
  grid-column: 2;
  column-gap: 1.25rem;
  margin-bottom: 1.5rem;

  .select, select {
    width: 100%;
  }

  .field:last-of-type {
    margin-bottom: 0px;
  }
}
</style>
