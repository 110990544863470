const getNFMNetworks = () => {
  return [
    'NFM',
    'NFM-BSW',
    'NFM-MID',
    'NFM-CTR'
  ]
}

const getRBRNetworks = () => {
  return [
    'AMPS',
    'Asserta',
    'Payer Compass',
    'ClearHealth',
    '6Degrees'
  ]
}

const getThirdPartyNetworks = () => {
  return [
    'Cigna',
    'First Health',
    'Multiplan',
    'HPS',
    'Independent Health',
    '6Degrees',
    'Prime'
  ]
}

// DEPRECATED: all third party networks should allow VPC
const getVpcEnabledThirdPartyNetworks = () => {
  return [
    'Cigna',
    'HPS'
  ]
}

module.exports = {
  getRBRNetworks,
  getNFMNetworks,
  getThirdPartyNetworks,
  getVpcEnabledThirdPartyNetworks
}
