<script>
import Multiselect from 'vue-multiselect'

export default {
  extends: Multiselect,
  name: 'vue-multiselect',
  computed: {
    inputStyle () {
      if (this.searchable || (this.multiple && this.value && this.value.length)) {
        // Hide input by setting the width to 0 allowing it to receive focus
        return { width: '0', position: 'absolute', padding: '0' }
      } else {
        return ''
      }
    }
  }
}
</script>
