export enum NetworkDirectory {
  CENTIVO = 'Centivo',
  EXTERNAL = 'External/RBP',
  MIXED = 'Centivo + External/RBP'
}

/** Newtwork series options */
export interface NetworkSeries {
  name: string
  values: string[]
}

/** Pre-enrollment config */
export interface PreEnrollment {
  siteURL: string
  customContent: boolean
  publish: boolean
  hasCustomization?: boolean
}

/** Client network object */
export interface Network {
  /** Network id */
  name: string | undefined
  directoryType: NetworkDirectory | undefined
  preEnrollment: PreEnrollment | undefined
  networkSeries: NetworkSeries[] | never[]
  isValid: boolean
  includePreEnrollmentSite: boolean | undefined
  uuid: string
  requireVpcModule?: boolean | null
  rbpAdministrator?: boolean | null
  thirdPartyNetwork?: string | null
}

export interface Client {
  /** Client name */
  name: string
  clientId?: string
  /** Network list */
  networkConfigs: Network[]
  preEnrollment: PreEnrollment
}

export interface SiteCustomContentConfig {
  FORMAT_PHONE: string,
  REACT_APP_HEADER_TEXT: string,
  REACT_APP_HERO_IMAGE: string,
  REACT_APP_LOGO: string,
  REACT_APP_NETWORK_OF_EXCELLENCE: string,
  REACT_APP_PHONE: string,
  REACT_APP_TEMPLATE: string,
  REACT_APP_WITH_CENTIVO_WILL_GET: string,
  SHOW_ALREADY_MEMBER: string,
  SHOW_DISCLAIMER: string,
  SHOW_TESTIMONIALS: string,
  SHOW_VPC: string
}

/** Custom config */
export interface CustomConfig {
  siteCustomContentConfig: SiteCustomContentConfig
}

export type NetworkCoreSeries =
   'Core network 100 series' |
   'Wisconsin network 600 series' |
   '300 series' |
   '400 series' |
   '500 series' |
   '700 series' |
   '800 series' |
   '900 series'

export interface NetworkSeriesOptions extends Partial<Record<NetworkCoreSeries, string[]>> {}

export namespace ClientApi {
  export enum NetworkDirectory {
    CENTIVO = 'Centivo',
    EXTERNAL = 'External/RBP',
    MIXED = 'Centivo+External/RBP'
  }

  /** Newtwork series options */
  export interface NetworkSeries {
    name: string
    values: string[]
  }

  /** Pre-enrollment config */
  export interface PreEnrollment {
    siteURL: string
    customContent: boolean
    publish: boolean
    hasCustomization?: boolean
  }

  /** Client network object */
  export interface Network {
    /** Network id */
    name: string | undefined
    directoryType: NetworkDirectory | undefined
    preEnrollment: PreEnrollment | undefined
    networkSeries: NetworkSeries[] | never[]
    isValid: boolean
    includePreEnrollmentSite: boolean | undefined
    uuid: string
  }

  export interface Client {
    /** Client name */
    name: string
    clientId?: string
    /** Network list */
    networkConfigs: Network[]
    preEnrollment: PreEnrollment
  }

  export interface SiteCustomContentConfig {
    FORMAT_PHONE: string,
    REACT_APP_HEADER_TEXT: string,
    REACT_APP_HERO_IMAGE: string,
    REACT_APP_LOGO: string,
    REACT_APP_NETWORK_OF_EXCELLENCE: string,
    REACT_APP_PHONE: string,
    REACT_APP_TEMPLATE: string,
    REACT_APP_WITH_CENTIVO_WILL_GET: string,
    SHOW_ALREADY_MEMBER: string,
    SHOW_DISCLAIMER: string,
    SHOW_TESTIMONIALS: string,
    SHOW_VPC: string
  }

  /** Custom config */
  export interface CustomConfig {
    siteCustomContentConfig: SiteCustomContentConfig
  }

  export type NetworkCoreSeries =
     'Core network 100 series' |
     'Wisconsin network 600 series' |
     '300 series' |
     '400 series' |
     '500 series' |
     '700 series' |
     '800 series' |
     '900 series'

   export interface NetworkSeriesOptions extends Partial<Record<NetworkCoreSeries, string[]>> {}
}
