<template>
  <div v-if="skipContainer">
    <div v-bind:class="showDeepValidationErrors && (externalMissingValue || externalNeedsVpcValue) ? 'box error-box external-series error-highlight' : 'box external-series'">
      <external-network-fields v-model="value" @setIsValid="setIsValid" :showDeepValidationErrors="showDeepValidationErrors" :externalMissingValue="externalMissingValue" :externalNeedsVpcValue="externalNeedsVpcValue"/>
    </div>
  </div>
  <network-series-container v-bind:class="showDeepValidationErrors && (externalMissingValue || externalNeedsVpcValue) ? 'external-series error-highlight' : 'external-series'" v-else>
    <external-network-fields v-model="value" @setIsValid="setIsValid" :showDeepValidationErrors="showDeepValidationErrors" :externalMissingValue="externalMissingValue" :externalNeedsVpcValue="externalNeedsVpcValue"/>
  </network-series-container>
</template>

<script>
import NetworkSeriesContainer from './NetworkSeriesContainer.vue'
import ExternalNetworkFields from './ExternalNetworkFields.vue'

export default {
  name: 'external-network-series',
  props: {
    skipContainer: Boolean,
    value: Object,
    showDeepValidationErrors: Boolean,
    externalMissingValue: Boolean,
    externalNeedsVpcValue: Boolean
  },
  components: {
    NetworkSeriesContainer,
    ExternalNetworkFields
  },
  methods: {
    setIsValid () {
      this.$emit('setIsValid')
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
    border: 1px solid #BBBDBF;
    border-radius: 4px;
    padding: 1.25rem 1rem;
    margin-top: 1.75rem;
    width: 35rem;
    margin-left: auto;
    grid-column: 2;
    column-gap: 1.25rem;
    margin-bottom: 1.5rem;
  }

.error-box {
  padding-bottom: 3rem;
}
</style>
