<template>
  <div class="centivo plan-affiliations-form-wrapper">
    <div class="plan-affiliations-form">
      <div>
        <div class="plan-affiliation-content">
          <span class="steps">1</span>
          <p>Select the plan type</p>
        </div>
        <div class="plans-switch">
          <b-field>
            <b-radio
              size="is-large"
              native-value="Javelina"
              v-model="planType"
              @input="setCurrentPlanTypeClientIdList"
            >
              Javelina
            </b-radio>
          </b-field>
          <b-field>
            <b-radio
              size="is-large"
              native-value="HRP"
              v-model="planType"
              @input="setCurrentPlanTypeClientIdList"
            >
              HRP
            </b-radio>
          </b-field>
        </div>
      </div>

      <div v-if="planType">
        <div class="plan-affiliation-content">
          <span class="steps">2</span>
          <p>Select the client ID to affiliate the configurations to the plan</p>
        </div>
        <form>
          <DropDownComponent
            class="client-id-dropdown"
            :name="clientId"
            :planType="planType"
            label="Client ID"
            :options="clients"
            v-model="selectedClient"
            @update:selectedOption="onChangeHandler"
          />
        </form>
      </div>
      <div v-if="selectedClient && selectedClient.title !== 'NONE' && clientId != 'NONE'">
        <div class="plan-affiliation-content">
          <span class="steps">3</span>
          <p>Select a network name to affiliate to each plan ID</p>
        </div>

        <div class="table-wrapper">
          <div class="table-header">
            <p>PLAN ID</p>
            <p>NETWORK NAME</p>
          </div>
          <div class="table-content" v-if="selectedClient">
            <div
              v-for="item in (selectedClientCurrentPlans ?? [])"
              :key="item.planId"
              class="plans-list"
            >
              <div class="plan-id" v-if="item.planId">{{ item.planId }}</div>
                <form>
                  <DropDownComponent
                    :name="item.planId"
                    :options="networkNameOptions[item.planId]"
                    @update:selectedOption="onChangeNetworkNameHandler"
                    v-if="item.planId"
                  />
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropDownComponent from './../form/DropDownComponent.vue'
import { isEqual } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { obsIsEqual } from '@/helpers'

export default {
  props: {
    value: {
      required: false,
      type: Object,
      default () {
        return {}
      }
    },
    clientData: {
      required: false,
      type: Object
    },
    clientOptions: {
      required: false,
      type: Array,
      default () {
        return []
      }
    },
    plans: {
      require: true,
      type: Array
    },
    activeTab: Boolean
  },
  components: {
    DropDownComponent
  },
  data () {
    return {
      selectedClient: this.clientOptions.find(client => client.isSelected),
      previousClient: this.clientOptions.find(client => client.isSelected),
      originalClients: JSON.parse(JSON.stringify(this.clientOptions)),
      clients: [],
      networkNames: [],
      isSaveModalOpen: false,
      savedSuccess: false,
      saveError: false,
      hasValueChangedInOthertabs: false,
      planType: '',
      clientId: '',
      networkNameOptions: {},
      hrpSelectedClientPlans: [],
      javelinaSelectedClientPlans: [],
      selectedClientCurrentPlans: []
    }
  },
  computed: {
    ...mapGetters('networkConfig', ['getNetworkHasChanged'])
  },
  methods: {
    ...mapActions([
      'networkConfig/setShowNetworkAlertModal',
      'networkConfig/setNetworkHasChanged'
    ]),
    getNetworkNames () {
      const selectedDomainClient = this.clientData

      return selectedDomainClient
        ? selectedDomainClient.networkConfigs.map((nc) => {
          const ddOption = { title: nc.name, value: nc.uuid }
          return ddOption
        })
        : []
    },
    getPlanTypeFromSelectedClient (selectedClient) {
      let planType = ''

      if (!selectedClient) return planType

      const isHrpPlan = selectedClient.plans.some(plan => {
        if (plan.planId) return plan.planId.startsWith('HRP_')
      })

      if (isHrpPlan) {
        planType = 'HRP'
      } else {
        planType = 'Javelina'
      }

      return planType
    },
    getClients (plans = [], networkNames) {
      let selectedPlan = []

      const hrpPlans = plans.filter(plan => {
        return plan.planId && plan.planId.startsWith('HRP_')
      })

      const javelinaPlans = plans.filter(plan => {
        return !hrpPlans.includes(plan)
      })

      if (this.planType === 'HRP') {
        selectedPlan = hrpPlans
      } else {
        selectedPlan = javelinaPlans
      }

      const groupByClientId = selectedPlan.reduce(
        (result, item) => ({
          ...result,
          [item.clientId]: [...(result[item.clientId] || []), item]
        }),
        {}
      )

      let hasSelected = false
      const clients = Object.keys(groupByClientId).map((k) => {
        const disabled = groupByClientId[k].some(
          (plan) =>
            plan.networkAffiliation &&
        plan.networkAffiliation.clientName !== this.$route.params.clientName
        )

        const isSelected = groupByClientId[k].some(
          (plan) =>
            plan.networkAffiliation &&
        networkNames && networkNames.map(nn => nn.value).includes(plan.networkAffiliation.uuid)
        )

        if (isSelected) hasSelected = true

        return {
          title: k,
          plans: groupByClientId[k],
          disabled,
          isSelected
        }
      })

      clients.sort((a, b) => a.title.localeCompare(b.title))

      // Allow unaffiliated plans (NONE)
      clients.unshift({
        title: 'NONE',
        plans: null,
        disabled: false,
        isSelected: !hasSelected
      })

      return clients
    },
    setCurrentPlanTypeClientIdList () {
      this.clients = this.getClients(this.plans, this.networkNames)

      const selectedClientPlanType = this.getPlanTypeFromSelectedClient(this.selectedClient)

      if (
        selectedClientPlanType &&
        selectedClientPlanType === this.planType &&
        this.selectedClient.title !== this.clientId
      ) {
        this.clientId = this.selectedClient.title
      } else {
        this.clientId = this.clients[0].title
      }
    },
    onChangeHandler (e) {
      this.selectedClient = this.clientOptions.find(
        (c) => c.title === e.target.value
      )
      this.clientId = e.target.value
    },
    onChangeNetworkNameHandler (e) {
      const selectedNetwork = this.networkNames.find(
        (nn) => nn.value === e.target.value
      )
      if (!selectedNetwork) {
        return
      }

      this.selectedClient = {
        ...this.selectedClient,
        plans: this.selectedClient.plans.map((plan) => {
          if (plan.planId === e.target.name) {
            plan.networkAffiliation = {
              networkName: selectedNetwork.title,
              uuid: selectedNetwork.value,
              clientName: this.$route.params.clientName
            }
          }
          return plan
        })
      }
    },
    hasDataChanged (value) {
      if (!value) return

      if (this.selectedClient && this.selectedClient.plans) {
        this.hrpSelectedClientPlans = this.selectedClient.plans.filter(plan => {
          if (plan.planId) {
            return plan.planId.startsWith('HRP_')
          }
        })
        this.javelinaSelectedClientPlans = this.selectedClient.plans.filter(plan =>
          !this.hrpSelectedClientPlans.includes(plan)
        )

        if (this.planType === 'HRP') {
          this.selectedClientCurrentPlans = this.hrpSelectedClientPlans
        } else {
          this.selectedClientCurrentPlans = this.javelinaSelectedClientPlans
        }

        this.$emit('setClientCurrentPlansList', this.selectedClientCurrentPlans)

        this.networkNameOptions = this.selectedClientCurrentPlans.reduce(
          (networkNameOptions, plan) => {
            networkNameOptions[plan.planId] = this.networkNames.map(networkName => {
              return {
                ...networkName,
                isSelected:
                  plan.networkAffiliation &&
                  plan.networkAffiliation.networkName &&
                  plan.networkAffiliation.uuid === networkName.value
              }
            })

            return networkNameOptions
          }, {})
      }

      const rawNewValue = JSON.parse(JSON.stringify(value))
      const rawOriginalValue = JSON.parse(JSON.stringify(this.originalClients.find(org => org.title === rawNewValue.title)))
      const isChanged = !isEqual(rawNewValue, rawOriginalValue)

      if (isChanged || rawNewValue?.title === 'NONE') {
        this.previousClient?.title === 'NONE' ? this.$emit('setSaveEnabled', undefined) : this.$emit('input', rawNewValue)
      } else {
        this.$emit('setSaveEnabled', undefined)
      }

      this.previousClient = value

      // if somethings have already changed in other tabs don't do anything
      if (!this.getNetworkHasChanged) {
        this['networkConfig/setNetworkHasChanged'](isChanged)
      } else {
        this.hasValueChangedInOthertabs = true
      }
    }
  },
  watch: {
    selectedClient: {
      handler: 'hasDataChanged',
      deep: true
    },
    networkConfigs: {
      handler: function (newVal) {
        this.networkNames = newVal
          ? newVal.map((nc) => {
            const ddOption = { title: nc.name, value: nc.uuid }
            return ddOption
          })
          : []
      },
      deep: true
    },
    activeTab: function () {
      this.saveError = false
      this.savedSuccess = false
    },
    plans: {
      handler: function (newVal) {
        const networkNames = this.getNetworkNames()
        const clients = this.getClients(newVal, networkNames)

        if (!obsIsEqual(this.clients, clients)) {
          const selectedClient = clients.find(el => el.isSelected)

          this.clients = clients
          this.originalClients = JSON.parse(JSON.stringify(clients))
          this.selectedClient = selectedClient

          this.$emit('onAffiliationsLoad', selectedClient ? JSON.parse(JSON.stringify(selectedClient)) : null)
        }
      },
      deep: true
    },
    clientOptions: {
      handler: function (newVal) {
        this.selectedClient = newVal.find(client => client.isSelected)
        this.planType = this.getPlanTypeFromSelectedClient(this.selectedClient)
        this.clients = this.getClients(this.plans, this.networkNames)
        this.clientId = this.selectedClient?.isSelected ? this.selectedClient.title : this.clients[0].title
        this.networkNames = this.getNetworkNames()
        if (this.selectedClient && this.selectedClient.plans) {
          this.hrpSelectedClientPlans = this.selectedClient.plans.filter(plan => {
            if (plan.planId) {
              return plan.planId.startsWith('HRP_')
            }
          })
          this.javelinaSelectedClientPlans = this.selectedClient.plans.filter(plan =>
            !this.hrpSelectedClientPlans.includes(plan)
          )
        }
      },
      deep: true
    },
    planType: {
      handler: function (newVal) {
        if (newVal === 'HRP') {
          this.selectedClientCurrentPlans = this.hrpSelectedClientPlans
        } else {
          this.selectedClientCurrentPlans = this.javelinaSelectedClientPlans
        }

        this.$emit('setCurrentPlansList', this.selectedClientCurrentPlans)
      }
    }
  }
}
</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
