
import store from '@/store/store'
import { PropType, defineComponent } from 'vue'
import { mapActions } from 'vuex'
import { ClientApi } from '@/types/client-api'
import { addClientConfig, createNewConfigArray } from '@/helpers/add-client-config'

import RegisterNetwork from './form/RegisterNetwork.vue'
import { cloneDeep, isEqual } from 'lodash'

export interface ClientData {
  name: string
  networkConfigs: ClientApi.Network[]
}

export default defineComponent({
  name: 'client-form',
  components: {
    RegisterNetwork
  },
  props: {
    value: {
      required: false,
      type: Object as PropType<ClientData>,
      default () {
        return {
          name: '',
          networkConfigs: createNewConfigArray()
        }
      }
    },
    saveSuccess: {
      required: false,
      type: String
    },
    initialName: {
      type: String
    },
    showDeepValidationErrors: {
      type: Boolean
    },
    clientConfigNetworkUuids: {
      required: true
    }
  },
  mounted () {
    this.getNetworkSeriesList()
  },
  data () {
    return {
      data: cloneDeep(this.value),
      networkSeriesOptions: {},
      evenOptions: [] as number[],
      clientNameList: this.getClientNameListFromStore(),
      clientNameError: undefined as string | undefined,
      loaded: false,
      lastIndex: this.value.networkConfigs ? this.value.networkConfigs.length - 1 : 0
    }
  },
  methods: {
    ...mapActions(['networkConfig/setNetworkHasChanged']),
    addNetwork () {
      this.data.networkConfigs = [...this.data.networkConfigs, addClientConfig()]
      this.lastIndex = this.data.networkConfigs.length - 1
      this.$emit('hideValidationErrors')
    },
    deleteNetwork (index: Number) {
      this.data.networkConfigs.splice(index, 1)
      this.lastIndex = this.data.networkConfigs.length - 1

      if (this.data.networkConfigs.length < 1) {
        this.addNetwork()
      }
      this.setSaveEnabled()
    },
    getNetworkSeriesList () {
      store
        .dispatch('networkConfig/getNetworkSeriesOptions')
        .then(() => {
          this.networkSeriesOptions = this.getNetworkSeriesFromStore()

          this.evenOptions = [] as number[]
          for (let i = 0; i < Object.keys(this.networkSeriesOptions).length; i += 2) {
            this.evenOptions.push(i)
          }
        })
        .catch(console.error)
    },
    getNetworkSeriesFromStore () {
      return store.getters['networkConfig/getNetworkSeriesOptions']
    },
    getClientNameListFromStore () {
      return store.getters['networkConfig/getClientNameList']
    },
    validateClientName () {
      const lowerName = this.data.name.toLowerCase().trim()

      if (!lowerName.length) this.clientNameError = 'Please fill out this form'
      else if (
        this.clientNameList &&
        this.clientNameList.length &&
        lowerName !== this.initialName &&
        this.clientNameList.includes(lowerName)
      ) {
        this.clientNameError = 'Choose a different client ID this one is not available'
      } else if (!/^[A-Za-z0-9\s]*$/.test(lowerName)) {
        this.clientNameError = 'Symbols are not allowed in the client name'
      } else this.clientNameError = undefined

      this.setSaveEnabled()
    },
    setSaveEnabled () {
      this.$emit(
        'setSaveEnabled',
        !!this.data.name &&
        !!this.data.name.trim() &&
        !this.clientNameError &&
        !!this.data.networkConfigs &&
        this.data.networkConfigs.length > 0
      )
    }
  },
  watch: {
    data: {
      handler: function (newVal) {
        if (!isEqual(newVal, this.value)) {
          this.$emit('input', newVal)
        }
      },
      deep: true
    },
    value: function (newVal) {
      if (!isEqual(newVal, this.data)) {
        this.data = cloneDeep(newVal)
        this.loaded = true
        this.lastIndex = newVal.networkConfigs ? newVal.networkConfigs.length - 1 : 0
      }
    }
  }
})
