<template>
  <network-series-container v-bind:class="showDeepValidationErrors && (networksMissingValue || networksIncomplete) ? 'centivo-series error-highlight' : 'centivo-series'">
    <h3>You must select at least one series. If you select a 100 series then 600 series is conditional.</h3>
    <span v-for="(index) in evenOptions" :key="index">
      <div v-bind:class="index === 0 ? 'field-row  field-connect' :'field-row'">
        <b-field v-for="(series, fieldIndex) in Object.keys(networkSeriesOptions).slice(index, index + 2)" :key="fieldIndex" :label="series">
          <CustomSelect
            v-if="singleSelectSeries.includes(series)"
            :options="networkSeriesOptions[series] ? networkSeriesOptions[series] : []"
            placeholder="Select"
            v-model="value.networkSeries[index + fieldIndex]"
            :can-delete="true"
            :default="value.networkSeries[index + fieldIndex]"
            @input="setIsValid"
            :type="showDeepValidationErrors && !value.networkSeries[index + fieldIndex] && (networksMissingValue || (networksIncomplete && index === 0)) ? 'is-danger' : ''"
            >
          </CustomSelect>
          <multiselect v-else
            :options="networkSeriesOptions[series] ? networkSeriesOptions[series] : []"
            placeholder="Select"
            :limit="2"
            :value="value.networkSeries[index + fieldIndex]"
            v-model="value.networkSeries[index + fieldIndex]"
            :on-select-data="index + fieldIndex"
            @onSelect="onSelect"
            :error="showDeepValidationErrors && !value.networkSeries[index + fieldIndex] && (networksMissingValue || (networksIncomplete && index === 0))"
            >
          </multiselect>
        </b-field>
      </div>
    </span>
    <span v-if="showDeepValidationErrors && (networksMissingValue || networksIncomplete)" class="duplicateNameError">
      <span><img src="@/assets/alert-circle@1x.svg" alt=""/></span>
      You must select an option to proceed
    </span>
  </network-series-container>
</template>

<script>
import NetworkSeriesContainer from './NetworkSeriesContainer.vue'
import CustomSelect from '@/components/custom-select.vue'
import Multiselect from '@/components/multi-select.vue'
import { ClientApi } from '@/types/client-api'
import { cloneDeep } from 'lodash'

export default {
  name: 'centivo-network-series',
  props: {
    value: ClientApi.Network,
    networkSeriesOptions: ClientApi.NetworkSeriesOptions,
    evenOptions: [],
    showDeepValidationErrors: Boolean,
    networksMissingValue: Boolean,
    networksIncomplete: Boolean
  },
  components: {
    NetworkSeriesContainer,
    CustomSelect,
    Multiselect
  },
  data () {
    return {
      singleSelectSeries: ['Core network 100 series', 'Wisconsin network 600 series', '400 series'],
      lastSelectedOptions: []
    }
  },
  created () {
    this.$watch(() => this.value.networkSeries[0], (newVal) => {
      if (newVal && !this.value.networkSeries[1]) {
        this.value.networkSeries[1] = '601'
        this.setIsValid()
      }
    })
  },
  methods: {
    onSelect (selections, index) {
      const series = cloneDeep(this.value.networkSeries)
      series[index] = selections
      this.value.networkSeries = series
      this.setIsValid()
    },
    setIsValid () {
      this.$emit('setIsValid')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.centivo-series {
  padding-top: 1.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0px;
  border-radius: 4px;

  h3 {
    grid-column: span 2;
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 1.25rem;
    margin-left: .5rem;
  }

  .field {
    margin-right: 9px;
    margin-left: 9px;
  }

  .field-row {
    display: inline-flex;
  }

  .field-connect {
    background: #f6f7fa;
    height: 91px;
    margin-bottom: 20px;
  }
}
</style>
