
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'check-icon',
  props: {
    'is-loading': Boolean,
    value: Boolean
  }
})
