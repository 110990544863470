
import { PropType, defineComponent } from 'vue'
import CentivoNetworkSeries from './CentivoNetworkSeries.vue'
import ExternalNetworkSeries from './ExternalNetworkSeries.vue'
import { Network, NetworkSeriesOptions, NetworkDirectory } from '@/types/client-api'
import AlertModal from '@/components/alert-modal.vue'
import { isEmpty } from 'lodash'

export default defineComponent({
  name: 'register-network',
  props: {
    index: {
      required: true,
      type: Number
    },
    value: {
      type: Object as PropType<Network>,
      required: true
    },
    networkSeriesOptions: {
      type: Object as PropType<NetworkSeriesOptions>,
      required: true
    },
    evenOptions: {
      required: true,
      type: Array
    },
    lastIndex: {
      type: Number
    },
    loaded: {
      type: Boolean
    },
    allConfigs: {
      required: true,
      type: Array as PropType<Network[]>
    },
    showDeepValidationErrors: {
      type: Boolean
    },
    clientConfigNetworkUuids: {
      required: true,
      type: Array
    }
  },
  components: {
    CentivoNetworkSeries,
    ExternalNetworkSeries,
    AlertModal
  },
  data () {
    return {
      isDeleteModalOpen: false,
      isNameInvalid: false,
      networksMissingValue: false,
      networksIncomplete: false,
      externalMissingValue: false,
      externalNeedsVpcValue: false
    }
  },
  methods: {
    update (key: string, value: any) {
      this.value[key] = value
      this.setIsValid()
    },
    setDefaultValue (directory: NetworkDirectory) {
      if (directory === NetworkDirectory.EXTERNAL) {
        this.value.includePreEnrollmentSite = false
      }

      this.setIsValid()
    },
    onDeleteClick (index: number) {
      if (this.clientConfigNetworkUuids.includes(this.value?.uuid)) {
        this.isDeleteModalOpen = true
      } else {
        this.deleteNetwork(index)
      }
    },
    deleteNetwork (index: number) {
      this.isDeleteModalOpen = false
      this.$emit('deleteNetwork', index)
    },
    setIsValid (shallow: boolean = false) {
      this.value.isValid = this.isNetworkValid(shallow)
      if (this.index === 0) {
        this.$emit('setSaveEnabled')
      }
    },
    isNetworkValid (shallow: boolean = false) {
      let valid = true
      this.isNameInvalid = false
      this.networksMissingValue = false
      this.networksIncomplete = false
      this.externalMissingValue = false
      this.externalNeedsVpcValue = false

      if (!this.isNameValid()) {
        if (shallow) {
          return false
        }
        valid = false
      }

      if (!this.value?.directoryType) {
        if (shallow) {
          return false
        }
        valid = false
      }

      if (this.value?.includePreEnrollmentSite === undefined || this.value?.includePreEnrollmentSite === null) {
        if (shallow) {
          return false
        }
        valid = false
      }

      if (!this.isNetworkSectionValid()) {
        if (shallow) {
          return false
        }
        valid = false
      }

      if (!this.isExternalSectionValid()) {
        if (shallow) {
          return false
        }
        valid = false
      }

      return valid
    },
    isNameValid () {
      if (!this.value?.name || !this.value?.name.trim()) {
        this.isNameInvalid = true
        return false
      }
      const trimmedName = this.value?.name.toLowerCase().trim()
      for (const config of this.allConfigs.slice(0, this.index)) {
        if (!config.name) {
          continue
        }
        if (config.name.toLowerCase().trim() === trimmedName) {
          this.isNameInvalid = true
          return false
        }
      }

      return true
    },
    isNetworkSectionValid () {
      let valid = true
      if (this.value?.directoryType === NetworkDirectory.CENTIVO || this.value?.directoryType === NetworkDirectory.MIXED) {
        if (!this.value?.networkSeries || this.value?.networkSeries.length < 0) {
          valid = false
        } else {
          let hasValue = false
          for (const series of this.value?.networkSeries) {
            if (!isEmpty(series)) {
              hasValue = true
              break
            }
          }

          if (!hasValue) {
            valid = false
            this.networksMissingValue = true
          } else if (this.value?.networkSeries[0] && !this.value?.networkSeries[1]) {
            valid = false
            this.networksIncomplete = true
          }
        }
      }

      return valid
    },
    isExternalSectionValid () {
      let valid = true
      if (this.value?.directoryType === NetworkDirectory.EXTERNAL || this.value?.directoryType === NetworkDirectory.MIXED) {
        if (!this.value?.rbpAdministrator || !this.value?.thirdPartyNetwork) {
          valid = false
          this.externalMissingValue = true
        }
        this.externalNeedsVpcValue = this.value?.directoryType === NetworkDirectory.EXTERNAL && this.value?.thirdPartyNetwork !== 'None'
        if (!this.externalNeedsVpcValue && this.value) {
          this.value.requireVpcModule = undefined
        }
        if (this.externalNeedsVpcValue && (this.value?.requireVpcModule === undefined || this.value?.requireVpcModule === null)) {
          valid = false
        }
      }

      return valid
    }
  },
  watch: {
    loaded: function () {
      this.setIsValid()
    },
    showDeepValidationErrors: function () {
      this.setIsValid(false)
    },
    value: function () {
      this.setIsValid()
    }
  },
  computed: {
    showAddButton (): boolean {
      this.setIsValid()
      return this.index === this.lastIndex && !!this.value?.isValid
    }
  }
})
